<template>
  <div>
    <div class="notification-header tiny-header-text">
      You Have Scheduled a {{ meetingType }}
    </div>

    <div class="notification-content">
      <div class="text-italic size-text-13px">
        <div>
          Your {{ meetingType }} with <span class="semibold-weight"> {{ otherParticipantsList }}</span> is scheduled for {{ consultation.startsAt }}. You will receive an email confirmation with the {{ meetingType }} information. Those details are also available on your account dashboard.
        </div>

        <div class="top-20" v-if="inPersonMeetingWithLocation">
          Meeting Location: {{ appointmentMetadata.location }}
        </div>
      </div>

      <div class="row top-20" v-if="showButtons">
        <div class="col-sm-6">
          <button type="button" class="nv-button-white smaller" @click="onCancel({ appointmentId: appointmentMetadata.id, consultationId: consultation.id })">Cancel</button>
        </div>

        <div class="col-sm-6 top-10-xs">
          <button type="button" class="nv-button-white smaller" @click="onReschedule({ appointmentId: appointmentMetadata.id, consultationId: consultation.id })">Reschedule</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Appointment from 'resources/marketplace/appointment.js';
export default {
  name: 'ConsultationScheduled',

  props: {
    appointmentMetadata: {
      type:     Object,
      required: true
    },

    consultation: {
      type:     Object,
      required: true
    },

    otherParticipantsList: {
      type:     String,
      required: true
    },

    onReschedule: {
      type:    Function,
      required: true
    },

    onCancel: {
      type:    Function,
      required: true
    },

    showButtons: {
      type:    Boolean,
      default: false
    }
  },

  computed: {
    inPersonMeetingWithLocation() {
      return this.appointmentMetadata.type === 'in-person' && this.appointmentMetadata.location;
    },

    appointmentResource() {
      return new Appointment(this.appointmentMetadata);
    },

    meetingType() {
      return this.appointmentResource.typeText();
    }
  }
};
</script>
