<template>
  <div>
    <div class="notification-header tiny-header-text">
      Your {{ appointmentType }} has expired
    </div>

    <div class="notification-content">
      <div class="text-italic size-text-13px">
        It looks like your scheduled {{ appointmentType }} time with <span class="semibold-weight">{{ otherParticipantsList }}</span> has passed and you weren&rsquo;t able to connect.
      </div>

      <div class="top-20" v-if="showButtons">
        <button type="button" class="nv-button-blue smaller" @click="updateAvailability">
          Reschedule Your {{ appointmentType }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Appointment from 'resources/marketplace/appointment.js';

export default {
  name: 'ConsultationExpired',

  props: {
    appointmentMetadata: {
      type: Object,
      required: true
    },

    otherParticipantsList: {
      type: String,
      required: true
    },

    onUpdateAvailability: {
      type: Function,
      required: true
    },

    showButtons: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    appointmentResource() {
      return new Appointment(this.appointmentMetadata);
    },

    appointmentType() {
      return this.appointmentResource.typeText();
    }
  },

  methods: {
    updateAvailability() {
      this.onUpdateAvailability({ appointmentId: this.appointmentMetadata.id });
    }
  }
};
</script>
