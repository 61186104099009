<template>
  <div>
    <div class="notification-header tiny-header-text">
      Consultation Completed
    </div>

    <div class="notification-content">
      <div class="text-italic size-text-13px">
        Request a formal proposal for your project (<span class="semibold-weight">{{ nameForClientsReference }}</span>). You will be notified by email when the proposal is ready.
      </div>

      <div class="top-20" v-if="showButtons">
        <confirmation-modal
          ref="confirmationModal"
          header="Confirm"
          :message="modalConfirmationMessage"
          yes-btn-text="Yes"
          no-btn-text="No"
          :on-confirm-action="requestProposal">
        </confirmation-modal>
        <button type="button" class="nv-button-blue smaller" @click="showConfirmModal">Request Proposal</button>
      </div>

      <div class="green-text semibold-weight size-text-13px top-20" v-if="showSuccessMessage">
        <svg-icon name="checkmark" class="base-icon right-10"></svg-icon>Proposal Requested
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmationModal from 'vue-app/shared/components/confirmation-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ConsultationCompleted',

  components: {
    ConfirmationModal,
    SvgIcon
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    nameForClientsReference: {
      type: String,
      required: true
    },

    showButtons: {
      type:    Boolean,
      default: false
    },

    onRequestProposal: {
      type: Function,
      required: true
    },

    showSuccessMessage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    modalConfirmationMessage() {
      return `Are you sure you want to request a formal proposal from ${this.possibleLawyer.fullName}?`;
    }
  },

  methods: {
    showConfirmModal() {
      this.$refs.confirmationModal.openModal();
    },

    requestProposal() {
      this.onRequestProposal({ possibleLawyerId: this.possibleLawyer.id });
    }
  }
};
</script>
