import Vue from 'vue';
import vueComponents from './vue-components';

import AppointmentRequested from 'vue-app/mailboxer/notifications/appointment-requested.vue';
import AvailabilitySentConfirmation from 'vue-app/mailboxer/notifications/availability-sent-confirmation.vue';
import AlternativeTimeSuggestedNotification from 'vue-app/mailboxer/notifications/alternative-time-suggested.vue';
import ConsultationScheduled from 'vue-app/mailboxer/notifications/consultation-scheduled.vue';
import ConsultationRescheduleSuggested from 'vue-app/mailboxer/notifications/consultation-reschedule-suggested.vue';
import ConsultationRescheduleSent from 'vue-app/mailboxer/notifications/consultation-reschedule-sent.vue';
import ConsultationCompletedLawyer from 'vue-app/mailboxer/notifications/consultation-completed-lawyer.vue';
import ConsultationCancelled from 'vue-app/mailboxer/notifications/consultation-cancelled.vue';
import ConsultationCompleted from 'vue-app/mailboxer/notifications/consultation-completed.vue';
import LastAvailabilityExpired from 'vue-app/mailboxer/notifications/last-availability-expired.vue';
import ConsultationExpired from 'vue-app/mailboxer/notifications/consultation-expired.vue';

vueComponents
.directive('ngvAppointmentRequested', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('AppointmentRequested', AppointmentRequested));
}])

.directive('ngvAlternativeTimeSuggested', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('AlternativeTimeSuggestedNotification', AlternativeTimeSuggestedNotification));
}])

.directive('ngvAvailabilitySentConfirmation', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('AvailabilitySentConfirmation', AvailabilitySentConfirmation));
}])

.directive('ngvConsultationScheduled', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConsultationScheduled', ConsultationScheduled));
}])

.directive('ngvConsultationRescheduleSuggested', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConsultationRescheduleSuggested', ConsultationRescheduleSuggested));
}])

.directive('ngvConsultationRescheduleSent', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConsultationRescheduleSent', ConsultationRescheduleSent));
}])

.directive('ngvConsultationCompletedLawyer', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConsultationCompletedLawyer', ConsultationCompletedLawyer));
}])

.directive('ngvConsultationCancelled', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConsultationCancelled', ConsultationCancelled));
}])

.directive('ngvConsultationCompleted', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConsultationCompleted', ConsultationCompleted));
}])

.directive('ngvLastAvailabilityExpired', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('LastAvailabilityExpired', LastAvailabilityExpired));
}])

.directive('ngvConsultationExpired', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConsultationExpired', ConsultationExpired));
}]);
