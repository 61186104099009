<template>
  <div>
    <div class="notification-header tiny-header-text">
      Rescheduling Your {{ meetingType }}
    </div>

    <div class="notification-content">
      <div class="text-italic size-text-13px">
        <div class="bottom-20">
          <span class="semibold-weight" data-testid="participant-name">{{ participant.firstName }} {{ participant.lastName }}</span> would like to reschedule their {{ meetingType }} with you for the following reason:
        </div>

        <div class="semibold-weight">
          {{ consultation.reasonForCancel || '—' }}
        </div>
      </div>

      <div class="top-20" v-if="showButtons">
        <div class="text-italic size-text-13px bottom-20">
          If {{ participant.firstName }}&rsquo;s new proposed times do not suit your schedule, please suggest alternatives.
        </div>

        <button data-testid="response-btn" type="button" class="nv-button-blue smaller" @click="onUpdateCall({ appointmentId: appointmentMetadata.id })">Respond To {{ buttonText }} Request</button>
      </div>
    </div>
  </div>
</template>

<script>
import Appointment from 'resources/marketplace/appointment.js';
export default {
  name: 'ConsultationRescheduleSuggested',

  props: {
    appointmentMetadata: {
      type: Object,
      required: true
    },

    consultation: {
      type: Object,
      required: true
    },

    participant: {
      type: Object,
      required: true
    },

    showButtons: {
      type:    Boolean,
      default: false
    },

    onUpdateCall: {
      type: Function,
      required: true
    }
  },

  computed: {
    appointmentResource() {
      return new Appointment(this.appointmentMetadata);
    },

    meetingType() {
      return this.appointmentResource.typeText();
    },

    buttonText() {
      return this.appointmentResource.buttonText();
    }

  }
};
</script>
