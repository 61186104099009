<template>
  <div>
    <div class="notification-header tiny-header-text">
      Consultation Completed
    </div>

    <div class="notification-content">
      <div class="text-italic size-text-13px" data-testid="consultation-completed-body">
        You have completed your {{ meetingType }} with <span class="semibold-weight">{{ requester.firstName }}</span>, and may now send a formal proposal for the project. If your formal proposal is accepted, you will be notified by email and can contact <span class="semibold-weight">{{ requester.firstName }}</span> to start work.
      </div>

      <div class="top-20" v-if="showButtons">
        <button type="button" class="nv-button-blue smaller" @click="createProposal">Build Proposal</button>
      </div>
    </div>
  </div>
</template>

<script>
import Appointment from 'resources/marketplace/appointment.js';
export default {
  name: 'ConsultationCompletedLawyer',

  props: {
    appointmentMetadata: {
      type: Object,
      required: true
    },

    possibleLawyer: {
      type: Object,
      required: true
    },

    requester: {
      type: Object,
      required: true
    },

    onCreateProposal: {
      type: Function,
      required: true
    },

    counselRequest: {
      type: Object,
      required: true
    },

    showButtons: {
      type:    Boolean,
      default: false
    }
  },

  computed: {
    appointmentResource() {
      return new Appointment(this.appointmentMetadata);
    },

    meetingType() {
      return this.appointmentResource.typeText();
    }
  },

  methods: {
    createProposal() {
      this.onCreateProposal({ possibleLawyerId: this.possibleLawyer.id, counselRequestId: this.counselRequest.id });
    }
  }
};
</script>
